export default [
  {
    header: 'Admin-Stuff',
  },
  {
    title: 'Admin-Dashboard',
    icon: 'UserIcon',
    action: 'manage',
    route: 'Admin-Dashboard',
  },
  {
    title: 'Licenses',
    icon: 'LayersIcon',
    action: 'manage',
    route: 'License',
  },
]
